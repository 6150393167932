.contact-section {
    width: 100%;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin: 16px
}

.contact-section fieldset {
    border: none;
    padding: 10px;
}

.contact-section legend {
    font-size: 25px;
    font-weight: 500;
    margin-left: 10px;
}


.contact-section label {
    opacity: 0.6;
    font-size: 12px;
    font-weight: 500;
}


.inputBox {
    display: flex;
    flex-flow: column;
    height: auto;
    margin: 36px;
}

.contact-section input, textarea {
    height: 40px;
    background-color: white;
    border: none;
    border-bottom: 1px solid #efefef;
    outline: none;
    font-size: 16px;
    user-select: text;
}

/* .contact-section  {
    height: 40px;
    background-color: white;
    border: none;
    border-bottom: 1px solid #efefef;
    outline: none;
    font-size: 16px;
    user-select: text;
} */
