.button {
    background-color: white;
    /* color: black; */
    border-color: rgba(24, 20, 20, 0.6);
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 20vw;
    margin: 2rem;
    width: max-content;
    letter-spacing: 0.2rem;
    border: 2px solid rgba(199, 151, 151, 0.801);;
}

.button:hover {
    background-color: #f5f1f1;
}
