.instagram-icon {
    position: absolute;
    top: 0;
    left: 0;
    margin: 1.5rem;
    z-index: 100;
    font-size: 40px;
    /* Size of the icon */
    color: #E1306C
}
