.dialog-overlay {
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto; /* Added overflow property */
    position: fixed;
    top: 0;
    left: 0;
}

.dialog {
    margin-left: 1rem;
    margin-right: 2rem;
    background: white;
    /* width: 70vw;
    height: 40vh;
    margin: 16px;
    padding: 20px;
    border-radius: 5px; */
    /* box-shadow: 0 0 5px rgb(235 9 9 / 90%); */
    overflow-y: auto; /* Scrollable if content is too large */
    /* border-bottom: rgb(235 9 9 / 60%); */
}
/* https://codepen.io/_rahul/pen/rNQQxjJ */

.dialog-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    flex-direction: column;
}