.hamburger {
    width: 30px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    z-index: 20;
    position: absolute;
    top: 10%;
    right: 10%;
    margin: 8px;
    padding: 16px;
    &:hover {
        transform: scale(1.2);
    }
}

.line {
    width: 100%;
    height: 3px;
    background-color: black;
    transition: all 0.3s ease-in-out;
}

.hamburger.active .line:nth-child(1) {
    transform: translateY(8px) rotate(40deg);
}

.hamburger.active .line:nth-child(2) {
    opacity: 0;
}

.hamburger.active .line:nth-child(3) {
    transform: translateY(-8px) rotate(-40deg);}