.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  padding: 16px;
}

.portfolio-item {
  overflow: hidden;
  position: relative;
}

.portfolio-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.toggle-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
