body {
  margin: 0;
  padding: 0;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.2rem;
}

h1 {
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.2rem;
  margin: 1rem;
}

h2, h3, h4 {
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 300;
  font-style: normal;
  margin: 1rem;
  letter-spacing: 0.2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  margin: 0;
  padding: 0;
}