.container {
    display: flex;
    width: 100%;
    height:55vh;  
    position: relative;
}

.right {
    width: 100%;        /* for example */
    height:55vh;       /* for example */
    overflow: hidden;    /* this ensures no scrolling */
}

.right img {
    width: 100%;
    height: 100%;
    object-fit: cover;   /* this makes the image cover the entire container */
    object-position: center; /* this will center the image */
}
.left {
    flex: 1;
    background-color: #ffffff;
    color: rgb(209, 157, 157);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    width: 35%;
}


.title {
    font-size: xx-large;
    letter-spacing: 0.5em;
}

.subtitle {
    margin-bottom: 8px;
    /* font-size: large; */
}

.content {
    position: absolute;
    top: 65%;
    left: 30%;
    right: 30%;
    transform: translate(-50%, -50%);
    z-index: 1;
    margin-bottom: 8px;
}