.pricing-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

}

.btn-schedule {
    background-color: #ff4757;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn-schedule:hover {
    background-color: #e84393;
}


.lineFull {
    width: 100%;
    grid-column: 1 / -1;
    height: 1px;
    background: rgba(199, 151, 151, 0.801);
}
