.pricing-card {
    border: 1px solid #e0e0e0;
    padding: 20px;
    text-align:
        center;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.price {
    font-size: 24px;
    margin-top: 20px;
    color: #333;
}

.pricing-card h3 {
    font-weight: bold;
    margin-bottom: 20px;
    color: #555;
}

.pricing-card p {
    margin-top: 10px;
    color: #777;
}

.pricing-card p strong {
    color: #555;
}

.lineFull {
    color:antiquewhite
}
.grid-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 10px;
    margin-top: 20px;
    position: relative;
    text-align: left;
    /* column-gap: 24px; */
    /* justify-content: space-between; */
}
