.sidebar-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #f3eeee;
    padding-top: 60px;
    transition: 0.3s;
  }
  
  .sidebar-menu.active {
    left: 0;
    z-index: 2;
  }
  
  .sidebar-menu ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
  }
  
  .sidebar-menu li {
    padding: 8px 15px;
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 20px;
    cursor: pointer;
  }
  
  .sidebar-menu li:hover {
    background-color: #555;
  }
  